<template>
    <div class="swiperBoxx">
        <!-- <div class="itemLogin fiexLoginInput">
            <div class="completeLogin"  v-if="!$store.state.token">
                <div class="title">登录注册</div>
                <div class="listInput">
                    <input class="userName" type="text" placeholder="填写手机号,登录验证" v-model="userPhone">
                    <div class="num">
                        <input class="number" type="text" placeholder="请输入验证码" v-model="getSlat">
                        <div class="inputBtn" @click.stop="getCode">{{flag?count+'s':'获取验证码'}}</div>
                    </div>
                </div>
                <div class="btnn" @click.stop="login">立即登录</div>
                <div class="text">
                    登录即代表你已阅读并同意
                    <span>《用户协议》</span>
                    <span>《隐私政策》</span>
                </div>
            </div>
            <div class="noLogin" v-else>
                <div class="top">
                    <img :src="$store.state.user.headPic" alt="">
                </div>
                <div class="centera">
                    <span class="hellow">嗨,{{$store.state.user.nickname}}!</span>
                    <span class="myHome" @click="toMyhome">点击进入个人页面</span>
                </div>
                <div class="bot">
                    <input type="button" value="退出登录" @click="logOut">
                </div>
            </div>
        </div> -->
        <div class="swiper-container" ref="container">
            <div class="swiper-wrapper">
                <div class="swiper-slide"  v-for="(item,index) in itemArr" :key="index">
                    <img class="swiperImage" @click="toDetailUrl(item)" :src="item.connectImg" alt="">
                </div>
            </div>
            <!-- 显示滚动条 -->
            <!-- <div class="swiper-scrollbar"></div> -->
            <!-- 显示分页器 -->
            <div class="swiper-pagination"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
export default {
    props:{
        itemArr:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{

        }
    },
    methods:{
        //判断是外部连接还是内部链接
        toDetailUrl(item){
            if(item.isUrl == 1){
                let myStr=new RegExp("https");
                let regFlag = myStr.test(item.url);//test方法返回值为(true或者false)
                if(regFlag){
                    window.location.href = item.url;
                }else{
                    window.location.href = 'https://'+item.url;
                }
            }else if(item.isUrl == 0){
                this.$router.push({
                    path:'/courseList',
                    query:{
                        flag:true,
                        id:item.classId
                    }
                })
            }else{
                this.$router.push({
                    path:'/newsDetail',
                    query:{id:item.articleClassId}
                })
            }
        },
        //进入个人中心页面
        toMyhome(){
            this.$router.push({
                path:'/setting',
                query:{
                    index:2
                }
            })
        },
    },
    mounted(){
        new Swiper(this.$refs.container,{
           autoplay:this.itemArr.length>1?
           {
                delay: 4000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
           }:false,
           loop:true,
           pagination: {
                el: '.swiper-pagination',
                type:'bullets'
            }
        })
    }
}
</script>
<style>
    .swiper-pagination-bullet {
        width: 48px;
        height: 6px;
        display: inline-block;
        border-radius: 0%;
        background: #fff;
        opacity: 0.2;
    }
    .swiper-pagination-bullet-active{
        background-color: #097638;
    }
</style>
<style scoped lang="less">
.swiperBoxx{
    width: 100%;
    // height: 552px;
    .swiper-container{
        width: 100%;
        // height: 100%;
        .swiper-wrapper{
            width: 100%;
            // height: 100%;
            .swiper-slide{
                width: 100%;
                // height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                display: flex;
                flex-direction: row;
                justify-content: center;
                .swiperImage{
                    width: 100%;
                    // height: 552px;
                    object-fit: cover; 
                }
            }
        }
    }
}
</style>