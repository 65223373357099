<template>
    <div class="box">
        <div class="contion">
            <div class="title">{{itemObj.pendantName}}</div>
            <div class="text">{{itemObj.pendantIntroduce}}</div>
            <div class="swiper">
                <div class="left" @click="item(itemObj.pendantConnectVO[0])">
                    <div class="img">
                        <img :src="itemObj.pendantConnectVO[0].connectImg" alt="">
                    </div>
                    <div class="leftText">
                        <!-- <div class="showTitle">{{itemObj.pendantConnectVO[0].connectTitle}}</div> -->
                        <div class="showTitle">
                            <span class="leftWiText">{{itemObj.pendantConnectVO[0].connectTitle}}</span>
                            <span class="rightTit">{{itemObj.pendantConnectVO[0].time | toYearData(itemObj.pendantConnectVO[0].time)}}</span>
                        </div> 
                    </div>
                </div>
                <div class="centera" @click="item(itemObj.pendantConnectVO[1])">
                    <div class="img">
                        <img :src="itemObj.pendantConnectVO[1].connectImg" alt="">
                    </div>
                    <div class="centerText">
                        <span class="leftTitle">{{itemObj.pendantConnectVO[1].connectTitle}}</span><span class="centerTime">{{itemObj.pendantConnectVO[1].time | toYearData(itemObj.pendantConnectVO[1].time)}}</span>
                    </div>
                </div>
                <div class="left" @click="item(itemObj.pendantConnectVO[2])">
                    <div class="img">
                        <img :src="itemObj.pendantConnectVO[2].connectImg" alt="">
                    </div>
                    <div class="leftText">
                        <!-- <div class="showTitle">{{itemObj.pendantConnectVO[2].connectTitle}}</div> -->
                        <div class="showTitle">
                            <span class="leftWiText">{{itemObj.pendantConnectVO[2].connectTitle}}</span>
                            <span class="rightTit">{{itemObj.pendantConnectVO[1].time | toYearData(itemObj.pendantConnectVO[1].time)}}</span>
                        </div> 
                         
                    </div>
                </div>
            </div>
            <div class="toSome" @click="toAbout">发现更多>></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        itemObj:{
            type:Object,
            default:()=>{}
        }
    },
    methods:{
        //点击跳转到关于我们
        toAbout(){
            if(this.itemObj.isLinkUrl == 1){
                let myStr=new RegExp("https");
                let regFlag = myStr.test(this.itemObj.linkUrl);//test方法返回值为(true或者false)
                if(regFlag){
                    window.location.href = this.itemObj.linkUrl;
                }else{
                    window.location.href = 'https://'+this.itemObj.linkUrl;
                }
            }else if(this.itemObj.isLinkUrl == 0){
                this.$router.push({
                    path:'/courseList',
                    query:{
                        flag:true,
                        id:this.itemObj.classId
                    }
                })
            }else{
                this.$router.push({
                    path:'/news'
                })
            }
            
        },
        //点击跳转
        item(str){
            if(str.isUrl == 1){
                let myStr=new RegExp("https");
                let regFlag = myStr.test(str.url);//test方法返回值为(true或者false)
                if(regFlag){
                    window.location.href = str.url;
                }else{
                    window.location.href = 'https://'+str.url;
                }
            }else if(str.isUrl == 0){
                this.$router.push({
                    path:'/courseList',
                    query:{
                        flag:true,
                        id:str.id
                    }
                })
            }else{
                this.$router.push({
                    path:'/newsDetail',
                    query:{id:str.connectId}
                })
            }
        }
    }
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 840px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contion{
        width: 1200px;
        height: 840px;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .title{
            font-size: 40px;
            font-weight: 400;
            line-height: 56px;
            color: #333333;
            position: relative;
        }
        .title::after{
            content: '';
            width: 61px;
            height: 4px;
            background: #FED23E;
            border-radius: 4px;
            position: absolute;
            bottom: -15px;
            left: 50px;
        }
        .text{
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: #333333;
            text-align: center;
        }
        .swiper{
            width: 100%;
            height: 407px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            .left{
                height: 300px;
                width: 349px;
                background: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius:4px;
                box-shadow: 0px 3px 6px rgba(0, 59, 8, 0.08);
                .img{
                    width: 342px;
                    height: 228px;
                    overflow: hidden;
                    img{
                        width: 342px;
                        height: 228px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 0px 0px;
                        transition: all .5s;
                    }
                }
                .leftText{
                    height: 72px;
                    width: 100%;
                    padding: 10px 20px 13px 20px;
                    color: #333333;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // .showTitle{
                    //     width: 100%;
                    //     overflow: hidden;
                    //     white-space: nowrap;
                    //     text-overflow: ellipsis;
                    //  }
                    
                    .showTitle{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .leftWiText{
                            width:100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .rightTit{
                            color: #999999;
                        }
                    }
                }
            }
            .left:hover{
                img{
                    transform: scale(1.2);
                }
                .leftText{
                    color: #097638;
                }
            }
            .centera{
                height: 407px;
                width: 509px;
                background: #fff;
                border-radius:4px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 3px 6px rgba(0, 59, 8, 0.08);
                .img{
                    width: 509px;
                    height: 339px;
                    overflow: hidden;
                    img{
                        width: 509px;
                        height: 339px;
                        border-radius: 4px 4px 0px 0px;
                        transition: all .5s;
                    }
                }
                .centerText{
                    height: 68px;
                    width: 100%;
                    padding: 0 20px;
                    color: #333333;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .leftTitle{
                        width: 175px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .centerTime{
                        color: #999;
                    }
                }
            }
            .centera:hover{
                img{
                    transform: scale(1.4);
                }
                .centerText{
                    color: #097638;
                }
            }
        }
        .toSome{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #097638;
            cursor: pointer;
        }
    }
}
</style>