<template>
    <div class="box">
        <div class="contation">
            <div class="topTitle">
                <div class="maxTitle">{{itemObj.pendantName}}</div>
            </div>
            <div class="Contion">
                <div class="left">
                    <img :src="url" alt="" @click="toDetail">
                </div>
                <div class="right">
                    <div class="item" v-for="(item,index) in itemObj.pendantConnectVO" @click="toItem(index)" :key="index">
                        <div class="listIcon">
                            <img v-if="currentIndex == index" src="@/assets/img/playing.png" alt="">
                            <img v-else src="@/assets/img/playy.png" alt="">
                        </div>
                        <div class="listTitle">
                            <div class="itemTop">{{item.connectTitle}}</div>
                            <div class="itemCenter">{{item.connectIntroduce}}</div>
                            <div class="itemBot">{{item.createTime | toTime(item.createTime)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="botSome" @click="toCourseList">发现更多>></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        itemObj:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return{
            url:'',
            currentIndex:0
        }
    },
    methods:{
        //跳转到哪个模块
        toDetail(){
            this.$router.push({
                path:'/playdetails',
                query:{id:this.itemObj.pendantConnectVO[this.currentIndex].id}
            })
        },
        //显示第几个图片
        toItem(index){
            this.currentIndex = index;
            this.url = this.itemObj.pendantConnectVO[index].connectImg;
        },
        //跳转到视频模块
        toCourseList(){
            if(this.itemObj.isLinkUrl){
                let myStr=new RegExp("https");
                let regFlag = myStr.test(this.itemObj.linkUrl);//test方法返回值为(true或者false)
                if(regFlag){
                    window.location.href = this.itemObj.linkUrl;
                }else{
                    window.location.href = 'https://'+this.itemObj.linkUrl;
                }
            }else{
                this.$router.push({
                    path:'/courseList',
                    query:{
                        flag:true,
                        id:this.itemObj.classId
                    }
                })
            }
        }
    },
    created(){
        this.url = this.itemObj.pendantConnectVO[0].connectImg;
    }
}
</script>

<style scoped lang="less">
    .box{
        width: 100%;
        height: 840px;                        
        background: #F8F8F8;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .contation{
            width: 1200px;
            height: 840px;
            padding: 60px 0 90px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .topTitle{
                width: 100%;
                height: 65px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .maxTitle{
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 56px;
                    color: #333333;
                    position: relative;
                }
                .maxTitle::after{
                    content: '';
                    width: 61px;
                    height: 4px;
                    position: absolute;
                    left: 50px;
                    bottom: -15px;
                    background: #FED23E;
                }
            }
            .Contion{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 438px;
                width: 100%;
                padding-right: 43px;
                .left{
                    width: 779px;
                    height: 438px;
                    img{
                        width: 779px;
                        height: 438px;
                    }
                }
                .right{
                     width: 350px;
                     height: 438px;
                     display: flex;
                     flex-direction: column;
                     justify-content: space-around;
                     align-items: center;
                     .item{
                         width: 100%;
                         display: flex;
                         flex-direction: row;
                         justify-content: space-between;
                         cursor: pointer;
                         .listIcon{
                             width: 25px;
                             height: 30px;
                             display: flex;
                             flex-direction: column;
                             justify-content: center;
                             img{
                                 width: 25px;
                                 height: 25px;
                                 border-radius: 50%;
                             }
                         }
                         .listTitle{
                             width: 310px;
                             display: flex;
                             flex-direction: column;
                             justify-content: space-around;
                             .itemTop{
                                width: 100%;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                font-size: 22px;
                                font-weight: 400;
                                line-height: 30px;
                                color: #333333;
                             }
                             .itemCenter{
                                width: 100%;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17px;
                                color: #999999;
                                margin: 5px 0 4px;
                             }
                             .itemBot{
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17px;
                                color: #999999;
                             }
                         }
                     }
                }
            }
            .botSome{
                font-size: 16px;
                line-height: 24px;
                color: #097638;
                cursor: pointer;
            }
        }
    }
</style>