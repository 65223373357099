<template>
  <div>
    <commonHeader :current-index="0"></commonHeader>
    <div v-for="(item,index) in homeList" :key="index">
      <swiper v-if="item.styleCode == 'pc_carsoule'" :itemArr="item.pendantConnectVO"></swiper>
      <classification v-if="item.styleCode == 'pc_choice'" :itemArr="item.pendantConnectVO"></classification>
      <school v-if="item.styleCode == 'pc_school'" :itemObj="item"></school>
      <teaching v-if="item.styleCode == 'pc_direct'" :itemObj="item"></teaching>
      <smallSchoolEnglish v-if="item.styleCode == 'pc_small'" :itemObj="item"></smallSchoolEnglish>
      <middleSchoolEnglish v-if="item.styleCode == 'pc_middle'" :itemObj="item"></middleSchoolEnglish>
      <arithmetic v-if="item.styleCode == 'pc_figure'" :itemObj="item"></arithmetic>
    </div>
    <commonFooter></commonFooter>
  </div>
</template>

<script>
import { indexList } from '@/common/public.js'
import commonHeader from '@/components/commonHeader'
import commonFooter from '@/components/commonFooter'
import swiper from '@/components/index/swiper'
import classification from '@/components/index/classification'
import school from '@/components/index/school'
import teaching from '@/components/index/teaching'
import smallSchoolEnglish from '@/components/index/smallSchoolEnglish'
import middleSchoolEnglish from '@/components/index/middleSchoolEnglish'
import arithmetic from '@/components/index/arithmetic'
export default {
  components:{
    commonHeader,
    swiper,
    classification,
    school,
    teaching,
    smallSchoolEnglish,
    middleSchoolEnglish,
    arithmetic,
    commonFooter
  },
  data(){
    return{
        homeList:[],//首页信息数组
    }
  },
  created(){
    this.$store.commit('userInfor')
    this.list()
  },
  methods:{
    //首页信息展示
    list(){
      indexList().then(res=>{
        this.homeList = res.result.mapList;
      })
    }
  },
  mounted(){
    
  }
}
</script>

<style>

</style>