<template>
    <div class="box">
        <div class="contation">
            <div class="top">
                <div class="title">
                    小学英语
                </div>
            </div>
            <div class="centera">
                <div class="item" v-for="(item,index) in itemObj.pendantConnectVO" :key="index" @click="itemChild(item.id,index)">
                    <div class="img">
                        <img :src="item.connectImg" alt="">
                    </div>
                    <div class="botText">
                        <p class="maxTitle">{{item.connectTitle}}</p>
                        <p class="smallText">{{item.createTime | toTime(item.createTime)}}</p>
                        <p class="vip" v-if="item.connectIsFree == 1">
                            <span class="two">免费</span>
                        </p>
                        <p class="vip" v-else>
                            <span class="one">VIP</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="toSome" @click="toCourseList">发现更多>></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        itemObj:{
            type:Object,
            default:()=>{}
        }
    },
    methods:{
        //点击跳转
        itemChild(id,index){
            if(this.itemObj.pendantConnectVO[index].isUrl == 1){
                window.location.href = this.itemObj.pendantConnectVO[index].url
            }else{
                this.$router.push({
                    path:'/playdetails',
                    query:{id}
                })
            }
        },
        //跳转到视频模块
        toCourseList(){
            this.$router.push({
                path:'/courseList',
                query:{
                    flag:true,
                    id:this.itemObj.classId
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 840px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contation{
        width: 1200px;
        height: 840px;
        padding: 60px 0 80px;
        .top{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .title{
                font-size: 40px;
                font-weight: 400;
                line-height: 56px;
                color: #333333;
                position: relative;
            }
            .title::after{
                content: '';
                width: 61px;
                height: 4px;
                background: #FED23E;
                position: absolute;
                left: 50px;
                bottom: -15px;
            }
        }
        .centera{
            width: 1200px;
            margin-top: 60px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // justify-content: space-between;
            .item:nth-child(5n+5) {
                margin-right: 0;
            }
            .item{
                height: 221px;
                width: 224px;
                margin-bottom: 30px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                margin-right: 20px;
                .img{
                    width: 224px;
                    height: 126px;
                    overflow: hidden;
                    img{
                        width: 224px;
                        height: 126px;
                        border-radius: 4px 4px 0px 0px;
                        transition: all .5s;
                    }
                }
                .botText{
                    width: 100%;
                    padding: 6px 10px;
                    display: flex;
                    flex-direction: column;
                    .maxTitle{
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #333333;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .smallText{
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #999999;
                        margin-top: 6px;
                    }
                    .vip{
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        margin-top: 8px;
                        .one{
                            color: #ff1D00;
                        }
                        .two{
                            color: #097638;
                        }
                    }
                }
            }
            .item:hover{
                img{
                    transform: scale(1.4);
                }
                .maxTitle{
                    color: #097638;
                }
            }
        }
        .toSome{
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #097638;
            cursor: pointer;
        }
    }
}
</style>