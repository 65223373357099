<template>
    <div class="box">
        <div class="list">
            <div class="tabBox">
                <div :class="['tab',heightFlag?'taba':'tabb']">
                    <div class="left">精选体验课</div>
                    <div class="right">
                        <div :class="currentIndex === index?'active':''" v-for="(item,index) in tabbar" :key="index" @click="tabbarItem(index,item.id)">{{item.name}}</div>
                        <p class="iconn" v-if="tabIconshow">
                            <img @click="toDown" v-if="heightFlag" src="@/assets/img/toUp.png" alt="">
                            <img @click="toUp" v-else src="@/assets/img/tabDown.png" alt="">
                        </p>
                    </div>
                </div>
            </div>
            <div class="vList">
                <div class="item" v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
                    <div class="top">
                        <img :src="item.coverUrl" alt="">
                    </div>
                    <div class="bot">
                        <div class="title">{{item.name}}</div>
                        <div class="time">{{item.publishTime | toTime(item.publishTime) }}</div>
                        <div class="vip"><span v-if="item.isFree" style="color:#097638">免费</span><span v-else>VIP</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { categoryList,curriculumList } from '@/common/public.js'
export default {
    props:{},
    data(){
        return{
            tabbar:[],
            currentIndex:0,
            page:1,//页码
            rows:3,//条数
            list:[],//课程列表
            tabIconshow:true,//控制箭头显示隐藏
            heightFlag:false,//控制上拉下拉箭头样式
            categoryId:''//课程分类id
        }
    },
    created(){
        this.categList()
    },
    methods:{
        //点击展开
        toDown(){
            this.heightFlag = false;
        },
        //点击收起
        toUp(){
            this.heightFlag = true;
        },
        tabbarItem(index,id){
            this.currentIndex = index;
            this.categoryId = id;
            this.getCurriculumList()
        },
        //跳转到详情页面
        toDetail(id){
            this.$router.push({
                path:'/playdetails',
                query:{id}
            })
        },
        //获取分类
        categList(){
            categoryList().then(res=>{
                if(res.result.mapList.length > 6){
                    this.tabIconshow = true;
                }else{
                    this.tabIconshow = false;
                }
                this.tabbar = res.result.mapList;
                this.categoryId = res.result.mapList[0].id;
                this.getCurriculumList()
            })
        },
        //获取课程列表
        getCurriculumList(){
            let data = {
                categoryId:this.categoryId,
                page:this.page,
                rows:this.rows
            };
            curriculumList(data).then(res=>{
                this.list = res.result.mapList;
            })
        }
    }
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 624px;
    display: flex;
    background: #F1F1F1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .list{
        width: 100%;
        height:624px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .tabBox{
            width: 100%;
            background: #fff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .taba{
                height: 100%;
            }
            .tabb{
                height: 103px;
                overflow: hidden;
            }
            .tab{
                width: 1200px;
                // height: 100px;
                display: flex;
                flex-direction: row; 
                justify-content: space-around;
                background: #fff;
                .left{
                    height: 100%;
                    padding-top: 35px;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 28px;
                    color: #333333;
                }
                .right{
                    width: 853px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    // justify-content: space-between;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 28px;
                    color: #707070;
                    position: relative;
                    div:nth-child(6n+6) {
                        margin-right: 0;
                    }
                    .active:nth-child(6n+6) {
                        margin-right: 0;
                    }
                    div{
                        color: #333333;
                        padding: 36px 0;
                        margin-right: 60px;
                        cursor: pointer;
                    }
                    div:hover{
                        color: #097638;
                    }
                    .active{
                        color: #097638;
                        border-bottom: 2px solid #FED23E;
                        padding: 36px 0;
                        margin-right: 60px;
                    }
                    .iconn{
                        width: 30px;
                        height: 30px !important;
                        position: absolute;
                        right: -10px;
                        top: 35px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 16px;
                            height: 9px;
                        }
                    }
                }
            }
         }
        .vList{
            width: 1200px;
            height: 524px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .item:nth-child(3n+3) {
                margin-right: 0;
            }
            .item{
                height: 373px;
                width: 379px;
                background: #fff;
                cursor: pointer;
                margin-right: 32px;
                border-radius: 4px;
                .top{
                    width: 379px;
                    height: 212px;
                    overflow: hidden;
                    img{
                        width: 379px;
                        height: 212px;
                        object-fit: cover;
                        background: #E1E1E1;
                        border-radius: 4px 4px 0px 0px;
                        transition: all .5s;
                    }
                }
                .bot{
                    height: 160px;
                    width: 100%;
                    padding: 10px 17px;
                    display: flex;
                    flex-direction: column;
                    .title{
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 22px;
                        font-weight: 400;
                        line-height: 30px;
                        color: #333333;
                    }
                    .time{
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        margin-top: 15px;
                        color: #999999;
                    }
                    .vip{
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 33px;
                        margin-top: 23px;
                        color: #FF1D00;
                    }
                }
            }
            .item:hover{
                img{
                    transform: scale(1.4);
                }
                .title{
                    color: #097638;
                }
            }
        }
    }
}
</style>