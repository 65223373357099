<template>
    <div class="box">
        <div class="contation">
            <div class="top">
                <div class="title">
                    <div class="zxs">{{itemObj.pendantName}}</div>
                </div>
                <div class="text">{{itemObj.pendantIntroduce}}</div>
            </div>
            <div class="centerList">
                <div class="item" v-for="(item,index) in itemObj.pendantConnectVO" :key="index" @click="toItem(item.id,index)">
                    <div class="img">
                        <img :src="item.connectImg" alt="">
                    </div> 
                    <div class="listBott">
                        <div class="listTitle">{{item.connectIntroduce}}</div>          
                        <div class="listTme">{{item.createTime | toTime(item.createTime)}}</div> 
                    </div>
                </div>
            </div>
            <div class="bot" @click="toCourseList">发现更多>></div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        itemObj:{
            type:Object,
            default:()=>{}
        }
    },
    methods:{
        //子元素点击事件
        toItem(id,index){
            if(this.itemObj.pendantConnectVO[index].isUrl == 1 ){
                 let myStr=new RegExp("https");
                let regFlag = myStr.test(this.itemObj.pendantConnectVO[index].url);//test方法返回值为(true或者false)
                if(regFlag){
                    window.location.href = this.itemObj.pendantConnectVO[index].url;
                }else{
                    window.location.href = 'https://'+this.itemObj.pendantConnectVO[index].url;
                }
            }else{
                this.$router.push({
                    path:'/playdetails',
                    query:{id}
                })
            }
        },
        //跳转到视频模块
        toCourseList(){
            if(this.itemObj.isLinkUrl){
                let myStr=new RegExp("https");
                let regFlag = myStr.test(this.itemObj.linkUrl);//test方法返回值为(true或者false)
                if(regFlag){
                    window.location.href = this.itemObj.linkUrl;
                }else{
                    window.location.href = 'https://'+this.itemObj.linkUrl;
                }
            }else{
                this.$router.push({
                    path:'/courseList',
                    query:{
                        flag:true,
                        id:this.itemObj.classId
                    }
                })
            }
            
        }
    }
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 840px; 
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contation{
        width: 1200px;
        height: 840px;
        padding: 60px 0 80px; 
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .top{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                font-weight: 400;
                line-height: 56px;
                color: #333333;
                .zxs{
                    position: relative;
                }
                .zxs::after{
                    content: '';
                    width: 61px;
                    height: 4px;
                    position: absolute;
                    left: 30px;
                    bottom: -15px;
                    background: #FED23E;
                }
            }
            .text{
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #333333;
                margin-top: 44px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }
        .centerList{
            width: 1200px;
            height: 428px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .item{
                width: 585px;
                height: 428px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                cursor: pointer;
                .img{
                    width: 583px;
                    height: 329px; 
                    overflow: hidden;
                    img{
                      width: 583px;
                      height: 329px;  
                      transition: all .5s;
                    }
                }
                .listBott{
                    width: 100%;
                    height: 99px;
                    padding: 10px 30px 10px 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .listTitle{
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #333333;
                    }
                    .listTme{
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #999999;
                    }
                }
            }
            .item:hover{
                img{
                    transform: scale(1.4);
                }
                .listTitle{
                    color: #097638;
                }
            }
        }
        .bot{
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #097638;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>