<template>
    <div class="box">
        <div class="top">
            <div class="contationTop">
                <div class="title">
                    <div class="middle">{{itemObj.pendantName}}</div>
                </div>
                <div class="text">
                    {{itemObj.pendantConnectVO[0].connectIntroduce}}
                </div>
            </div>
        </div>
        <div class="bot">
            <div class="contationBot">
                <div class="video">
                    <div class="fiex">
                        <img class="bgcPic" :src="itemObj.pendantConnectVO[0].connectImg" alt="">
                        <img class="icoo" src="@/assets/img/pouse.png" alt="" @click="toPlay">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        itemObj:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return{
            
        }
    },
    methods:{
        //点击播放
        toPlay(){
            this.$router.push({
                path:'/playdetails',
                query:{id:this.itemObj.pendantConnectVO[0].id}
            })
        },
    }
}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 840px;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top{
        width: 100%;
        height: 516px;
        // background: url('../../assets/img/middleSchool.png') center center no-repeat;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .contationTop{
             width: 1200px;
             height: 516px;
             padding: 60px 32px 0;
             .title{
                 width: 100%;
                 display: flex;
                 flex-direction: row;
                 justify-content: center;
                 align-items: center;
                 .middle{
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 56px;
                    color: #333;
                    position: relative;
                 }
                 .middle::after{
                    content: '';
                    width: 61px;
                    height: 4px;
                    border-radius: 4px;
                    background: #FED23E;
                    position: absolute;
                    left: 50px;
                    bottom: -15px;
                 }
             }
             .text{
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #333;
                margin-top: 41px;
             }
        }
    }
    .bot{
        width: 100%;
        height: 324px;
        background: #F8F8F8;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .contationBot{
            width: 1200px;
            height: 324px;
            padding: 0 32px;
            .video{
                width: 1200px;
                height: 324px;
                position: relative;
                .fiex{
                    width:879px;
                    height:494px;
                    position: absolute;
                    z-index: 999;
                    top: -247px;
                    left: 161px;
                    background: rgba(0,0,0,0.4);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .bgcPic{
                        position: absolute;
                        width:879px;
                        height:494px;
                        left: 0;
                        top: 0;
                    }
                    .icoo{
                        width: 88px;
                        height: 88px;
                        position: absolute;
                        left: 45%;
                        top: 45%;
                    }
                }
            }
        }
    }
}
</style>